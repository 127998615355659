<template>
  <div>
    <div
      id="login-shop-ware-in-drawer"
    >
      <p>{{ $store.state.swApiQuery.apiuser }} {{ $store.state.swApiQuery.apiurl }}</p>
      <template>
        <v-btn
          v-if="$store.state.swApiQuery.apiurl"
          color="primary"
          dark
          @click="doLogout"
        >
          Log Out
        </v-btn>
      </template>
    </div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!$store.state.swApiQuery.apiurl"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Log In
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Log In</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="apiurl"
                      label="Domain"
                      name="apiurl"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="apiuser"
                      class="purple-input"
                      label="API User Name"
                      name="apiuser"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="apikey"
                      label="API Pass"
                      class="purple-input"
                      name="apikey"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="doLogin"
              >
                LogIn
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  import { globalVars } from '@/globalVars.js'
  export default {
    name: 'LoginShopWareInDrawer',

    mixins: [Proxyable],

    data: () => ({
      swApiUrl: globalVars.swApiUrl,
      ebayApiUrl: globalVars.ebayApiUrl,
      ebayBusinesspolicyUrl: globalVars.ebayBusinesspolicyUrl,
      apiurl: '',
      apiuser: '',
      apikey: '',
      sandbox: globalVars.sandbox,
      dialog: false,
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'loginStatus', 'swApiQuery']),
      swApiQuery: {
        get () {
          return this.$store.state.swApiQuery
        },
        set (val) {
          this.$store.commit('SET_SW_API_QUERY', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          // title: this.$t('avatar'),
          // title: this.apiurl,
          title: this.swApiQuery.apiurl,
        }
      },
    },

    created () {
      this.getUserProfile()
    },

    methods: {
      ...mapMutations({
        setSwApiQuery: 'SET_SW_API_QUERY',
      }),

      getUserProfile () {
        this.$http.get(this.swApiUrl + '/sw-api/getUserProfile.php').then((resp) => {
          console.log('GPROF-swApiQuery:', resp)
          if (resp.data.success === true) {
            this.setSwApiQuery({
              apiurl: resp.data.swApiQuery.apiurl,
              apiuser: resp.data.swApiQuery.apiuser,
              apikey: resp.data.swApiQuery.apikey,
            })
          } else {
            this.dialog = false
          }
        })
      },

      doLogin () {
        console.log('DO LOGIN', window.location.hostname)
        var vtoolDevelopmentMode = true
        if (window.location.hostname === 'localhost') {
          vtoolDevelopmentMode = true
        } else {
          vtoolDevelopmentMode = false
        }
        const token = Buffer.from(`${this.apiuser}:${this.apiurl}`, 'utf8').toString('base64')
        // this.swApiQuery = '{ "apiurl": "' + this.apiurl + '", "apiuser": "' + this.apiuser + '", "apikey": "' + this.apikey + '", "sandbox": "' + this.sandbox + '"}'
        this.$http.post(this.swApiUrl + '/sw-api/swLogin.php', {
          headers: {
            Authorization: +'basicAuth' + token,
          },
          withCredentials: true,
          data: {
            vtoolDevelopmentMode: vtoolDevelopmentMode,
            apiurl: this.apiurl,
            sandbox: this.sandbox,
          },
          auth: {
            apiuser: this.apiuser,
            apikey: this.apikey,
          },
        }).then((resp) => {
          console.log('DDDLOGIN-swApiQuery:', resp.data)
          if (resp.data.success === true) {
            console.log('Login success')
            this.dialog = false
            this.setSwApiQuery({
              apiurl: this.apiurl,
              apiuser: this.apiuser,
              apikey: this.apikey,
              sandbox: this.sandbox,
            })
          } else {
            this.dialog = true
            this.setSwApiQuery({
              apiurl: '',
              apiuser: '',
              apikey: '',
              sandbox: '',
            })
          }
        })
      },
      doLogout () {
        this.$http.post(this.swApiUrl + '/sw-api/swLogin.php?logout=1', {
          data: {
            apiurl: '',
            apiuser: '',
            apikey: '',
            sandbox: '',
          },
        }).then((resp) => {
          console.log('DDDLOGOUT-swApiQuery:', resp.data)
          if (resp.data.success === true) {
            console.log('logout')
            this.dialog = true
            this.setSwApiQuery({
              apiurl: '',
              apiuser: '',
              apikey: '',
              sandbox: '',
            })
          } else {
            this.dialog = true
            this.setSwApiQuery({
              apiurl: '',
              apiuser: '',
              apikey: '',
              sandbox: '',
            })
          }
        })
      },
    },

  }
</script>

<style lang="sass">
  #login-shop-ware-in-drawer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
